/**
 * Navigation Component
 */

// Import Dependencies
@import 'src/assets/scss/dependencies.scss';

.navigation {
  position: absolute;
  top: rem-calc( 0 );
  left: rem-calc( 0 );
  min-height: 100vh;
  width: 80%;
  transform: translateX( -100% );
  background-color: get-color( backing );
  color: get-color( white );
  transition: all 150ms ease-in-out;

  @include media( '>mini' ) {
    width: rem-calc( 200 );
  }

  @include media( '>desktop' ) {
    position: static;
    flex: 0 0 rem-calc( 60 );
    transform: translateX( 0px );
  }

  &__close {
    position: absolute;
    top: rem-calc( 0 );
    right: rem-calc( 0 );
    padding: rem-calc( 10 );
    cursor: pointer;

    @include media( '>desktop' ) {
      display: none;
    }
  }

  .menu {
    position: relative;
    height: 100%;
    margin: rem-calc( 0 );
    padding: rem-calc( 60 0 40 0 );
    list-style: none;

    &__item {
      a,
      button {
        position: relative;
        display: block;
        overflow: hidden;
        padding: rem-calc( 8 12 10 12 );
        text-decoration: none;
        line-height: 0;
        color: get-color( white );
        fill: get-color( white );
        transition: background-color 200ms ease-in-out;

        span {
          opacity: 0;
          position: absolute;
          line-height: 1.5;
          top: calc( 50% - 13px );
          margin-left: rem-calc( 10 );
        }

        &:hover,
        &.active {
          background-color: lighten( get-color( backing ), 8% );
        }
      }

      button {
        display: none;
        width: 100%;
        border: none;
        background-color: transparent;
        cursor: pointer;

        @include media( '>desktop' ) {
          display: block;
        }
      }

      &--toggle {
        display: none;
        position: absolute;
        width: 100%;
        bottom: rem-calc( 20 );

        @include media( '>desktop' ) {
          display: block;
        }
      }
    }
  }

  &--open {
    transform: translateX( 0px );

    .menu__item {
      a {
        span {
          opacity: 1;
        }
      }
    }
  }

  &--expanded {
    flex: 0 0 rem-calc( 160 );

    .menu__item {
      a {
        span {
          opacity: 1;
        }
      }
    }
  }
}
