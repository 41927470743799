/**
 * Fonts
 */

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.woff');
  src: local('☺︎'),
       url('../fonts/Roboto-Regular.woff2') format('woff2'),
       url('../fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.woff');
  src: local('☺︎'),
       url('../fonts/Roboto-Bold.woff2') format('woff2'),
       url('../fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
