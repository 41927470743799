/**
 * Button Component
 */

// Import Dependencies
@import 'src/assets/scss/dependencies.scss';

.page {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  overflow: hidden;
  color: get-color( text );

  @include media( '>desktop' ) {
    height: 100%;
  }

  .main {
    flex: 1 0 200px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .content {
    flex: 1 0 rem-calc( 400 );
    padding: rem-calc( 20 20 );
  }
}

.fade-enter {
  .content {
    opacity: 0;
    z-index: 1;
  }
}

.fade-enter.fade-enter-active {
  .content {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
}
