/**
 * Header Component
 */

// Import Dependencies
@import 'src/assets/scss/dependencies.scss';

.header {
  flex: 0 0 rem-calc( 60 );
  background-color: #f7f7f7;
  background-color: get-color( blue );
  border-bottom: 1px solid #d7d7d7;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__menu,
  &__logo,
  &__user {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      display: inline;
      align-self: center;
      fill: get-color( white );
    }
  }

  &__menu {
    flex: 0 0 rem-calc( 90 );
    cursor: pointer;

    @include media( '>desktop' ) {
      display: none;
    }
  }
  &__logo {
    flex: 1 0 rem-calc( 120 );
    padding: rem-calc( 0 20 );
    font-size: 22px;
    font-size: rem-calc( 22 );
    font-weight: bold;
    color: get-color( white );

    @include media( '>desktop' ) {
      justify-content: start;
    }
  }
  &__user {
    flex: 0 0 rem-calc( 90 );
    cursor: pointer;
  }
}
