/**
 * Animations Module
 */

.animation {
  &--fade-in {
    animation: fade-in 150ms ease-in-out;
  }

  &--fade-out {
    animation: fade-out 150ms ease-in-out;
  }

  &--slide-in-top {
    animation: slide-in-top 200ms ease-in-out;
  }

  &--slide-in-bottom {
    animation: slide-in-bottom 200ms ease-in-out;
  }

  &--slide-in-right {
    animation: slide-in-right 200ms ease-in-out;
  }

  &--slide-in-left {
    animation: slide-in-left 200ms ease-in-out;
  }

  &--spin {
    animation: spin 1000ms linear infinite;
  }

  &--shake {
    animation: shake 1000ms linear;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY( -80px );
  }
  
  100% {
    transform: translateY( 0 );
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY( 80px );
  }
  
  100% {
    transform: translateY( 0 );
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX( -80px );
  }
  
  100% {
    transform: translateX( 0 );
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX( 80px );
  }
  
  100% {
    transform: translateX( 0 );
  }
}

@keyframes spin {
  0% {
    transform: rotate( 0deg );
  }
  100% {
    transform: rotate( 360deg );
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d( -1px, 0, 0 );
  }
    
  20%, 80% {
    transform: translate3d( 2px, 0, 0 );
  }
  
  30%, 50%, 70% {
    transform: translate3d( -4px, 0, 0 );
  }
  
  40%, 60% {
    transform: translate3d( 4px, 0, 0 );
  }
}

@keyframes spin {
  0% {
    transform: rotate( 0deg );
  }
  100% {
    transform: rotate( 360deg );
  }
}

@keyframes spin-loaded {
  0% {
    transform: rotate( 0deg );
    animation-timing-function: cubic-bezier( 0.55, 0.055, 0.675, 0.19 );
  }
  50% {
    transform: rotate( 900deg );
    animation-timing-function: cubic-bezier( 0.215, 0.61, 0.355, 1 );
  }
  100% {
    transform: rotate( 1800deg );
  }
}
