/**
 * Button Component
 */

// Import Dependencies
@import 'src/assets/scss/dependencies.scss';

.plain-page {
  overflow: hidden;
  color: get-color( text );

  @include media( '>desktop' ) {
    height: 100%;
  }

  .content {
    padding: rem-calc( 20 20 );
  }
}
