/**
 * Button Component
 */

// Import Dependencies
@import 'src/assets/scss/dependencies.scss';

.button {
  padding: rem-calc( 10 28 );
  background-color: get-color( blue );
  border: 2px solid get-color( blue );
  border-radius: rem-calc( 50 );
  color: get-color( white );
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: darken( get-color( blue ), 5% );
  }

  &--loading {

  }

  &--disabled {

  }
}
