/**
 * Global Styles
 */

// Import Dependencies
@import 'src/assets/scss/dependencies.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  background-color: #f7f7f7;
  min-height: 100vh;

  @include media( '>desktop' ) {
    height: 100%;
  }
}

body {
  min-height: 100vh;
	font-family: $base-font-family;
  line-height: $base-line-height;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  color: $base-font-color;

  @include media( '>desktop' ) {
    height: 100%;
  }
}

#app {
  min-height: 100vh;
  overflow: hidden;

  @include media( '>desktop' ) {
    height: 100%;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $base-font-family;
  margin: 0 0 1em 0;
  color: get-color( blue );
}
